import React from "react"
import Title from "../Title"
import {Link} from 'gatsby'

import * as styles from "../../css/products.module.css"
import img from "../../images/products.jpg"

const Products = () => {
  return (
    <section className={styles.about}>
      <Title title="Our" subtitle="products"/>
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            <img src={img} alt="company products" />
          </div>
        </article>
        
        <article className={styles.aboutInfo}>
            <h4>Mobile and Web Apps</h4>
          {/* <Link style={{textDecoration: 'None', color: '#0398F4'}} to="/apps">
            <h4 style={{color: '#0398F4'}}>Mobile and Web Apps</h4>
          </Link> */}

          <p>
            Millions of people around the world rely on Internet and their connected 
            devices for nearly everything, from their daily tasks, meetings, notes, 
            productivity, and for news and entertainment. Kids and adults all over the
            world now rely on online learning and education for the most part, specially
            in today's circumstances.
          </p>
          <p>
            We strive to build web and mobile software applications to power consumers' needs 
            for today and for the future.
          </p>

          {/* <br /> */}
          <p>
            <Link style={{textDecoration: 'None', color: '#0398F4'}} to="/apps">See all apps</Link>
          </p>
                {/* <button type="button" className="btn-primary">Read More</button> */}
        </article>
      </div>
    </section>
  )
}

export default Products
