import React from "react"
import { FaClipboardCheck, FaDolly, FaBookReader } from "react-icons/fa"

const services = [
  {
    icon: <FaBookReader />,
    title: "learning",
    text: "Learning comes in many ways and in many forms. We want to make learning fun and easy, with better experience for everyone. ",
  },
  {
    icon: <FaClipboardCheck />,
    title: "productivity",
    text: "You're at your best when there's someone or something to assist you with your work. We want to help you be more productive.",
  },
  {
    icon: <FaDolly />,
    title: "convenience",
    text: "Information retrieval and secure data storage are important but these aren't trivial. we want to make it easy.",
  },
  // {
  //   icon: <FaTree />,
  //   title: "open source",
  //   text: "We acknowledge and appreciate the contribution of open source community to the industry, and we will try to contribute wherever possible.",
  // },
];

export default services;