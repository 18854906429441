import React from "react";

import Layout from "../components/layout";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";

import Products from "../components/Home/Products";
import Services from "../components/Home/Services";
import { graphql } from "gatsby";

import Seo from '../components/SEO';
import seoLogo from "../images/seoImage.png";

const IndexFunc = (props) => {
  const { data } = props;
  console.log("Home page props:", props);

  const seoLogoImage = {
    src: seoLogo,
    height: 600,
    width: 600,
    alt: 'Logo',
  };

  return (
    <Layout>
      <Seo
        siteTitle={'Home'}
        seoTitle='Welcome to Malaud LLC'
        path={props.location.pathname}
        metaImage={seoLogoImage}
        description='Malaud LLC Home Page'
        keywords={[]}
        datePublished="04-03-2020"
        dateModified="11-01-2022"
  />                  
      
      <StyledHero overlay={true} img={data.defaultBcg.childImageSharp.gatsbyImageData}>
        <Banner title="Malaud" info="Software solutions for the new generation">
          {/* <Link className="btn-white" to="/about">Explore</Link> */}
        </Banner>
      </StyledHero>
      <Products />
      <Services />
    </Layout>
  );
}

export default IndexFunc;

export const IndexQuery = graphql`{
  defaultBcg: file(relativePath: {eq: "homeBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
    }
  }
}
`
